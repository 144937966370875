/**
 * JS for header realtime notification dropdown and websocket.
 */
import { io } from 'socket.io-client';
import { Header } from 'Shared/header';

// Keep references to all UI elements required.
let rtNotificationsToggle = $('rtNotificationsToggle');
let rtNotificationsContainer = $('rtNotificationsContainer');
let rtNotificationsDropdown = $('notificationsDropdown');
let unreadCount = $('unreadCount');
// The notifyBell element is only on Dozuki sites
let notifyBell = $('notify-bell');
let markAllReadButton = $('mark-all-read');
// On the notification history page in user profile
let rtNotificationHistory = $('notificationHistory');
let historyCount = $('historyCount');
// Notifications receieved after page load.
let receivedCount = 0;
let originalTitle = document.title;

/**
 * Get the current notification count displayed in the header or 0.
 */
function getCount() {
   return Number.parseInt(unreadCount && unreadCount.get('text'), 10);
}

/**
 * Set the current notification count to a new number.
 * The count is hidden if the new number is <= 0.
 */
function updateCount(count) {
   if (count < 0) {
      count = 0;
   }

   // Update the header
   unreadCount.toggleClass('active', count > 0);
   unreadCount.set('text', count);

   // Change the color of the bell on Dozuki (we don't show a number)
   when(notifyBell, el => {
      // When there are zero, turn it black ('read') and into just an
      // outline of the bell ('fa-bell-o');
      el.toggleClass('read', count === 0);
      el.toggleClass('fa-bell-o', count === 0);

      // When there are not zero, turn it orange ('unread') and into a
      // solid bell ('fa-bell')
      el.toggleClass('unread', count !== 0);
      el.toggleClass('fa-bell', count !== 0);
   });

   // Only update the history pane when it's showing.
   when(historyCount, el => {
      el.set('text', count);
      el.toggleClass('hidden', count === 0);
   });
}

/**
 * Stop a click from navigating away from the page.
 * All it has to do is call preventDefault before the other
 * handler is called to stop the browser from following the
 * link.
 */
function stopNavigation(ev) {
   ev.preventDefault();
}

/**
 * Flip the class of an element from "unread" to "read"
 * or vice versa.
 */
function toggleRead(el, read) {
   el.toggleClass('read', read).toggleClass('unread', !read);
}

/**
 * Tell the server that the user has acknowledged a notification.
 */
function markNotificationRead(ev) {
   // This only works with one notification right now, but we might
   // expand it to acknowledge multiple notifications in the future.
   let id = this.get('data-notificationid');

   // There will always be an icon.
   // If it's already read, ignore it.
   let icon = this.getChildren('a > i[.notification-icon]')[0];
   if (icon.hasClass('read')) {
      return;
   }

   if (this.getChildren('a')[0].get('href')) {
      // If the user clicked on a notification that links to another page,
      // save that information. This can be used to apply different logic
      // to the notification while the next page is still loading.
      this.set('data-leaving-page', 'data-leaving-page');
   }

   // Hit the acknowledge notification endpoint.
   new Request.API_2_0('notifications?ids=' + id, {
      method: 'GET',
      onSuccess: function (response) {
         // If it has the 'unread' class, that means it's in
         // the history pane.
         if (icon.hasClass('unread')) {
            toggleRead(icon, /* read */ true);

            // The parent element needs this class for sorting reasons,
            // so let's keep that updated as well.
            let li = icon.getParent('li');
            if (li.hasClass('unread')) {
               toggleRead(li, /* read */ true);
            }

            // We also need to delete it from the dropdown, but we gotta
            // find it first. This will break if we ever start marking
            // multiple as read at once.
            // TODO: Remove this when() after we enable RTN for everyone.
            when(rtNotificationsDropdown, el => {
               removeReadNotifications([id]);
            });
         } else {
            // If it doesn't have the unread class, it's in the dropdown.
            // IFF the notification history page is also showing, let's hide
            // the notification from there too.
            when(rtNotificationHistory, el => {
               let li = el.getChildren('ul > li[data-notificationid="' + id + '"]')[0];
               // If we're on the history page and marking a socket-delivered RTN read,
               // this element won't exist, so we have to double check.
               if (li) {
                  let icon = li.getChildren('a > i[.notification-icon]')[0];
                  toggleRead(icon, /* read */ true);

                  // The parent element needs this class for sorting reasons,
                  // so let's keep that updated as well.
                  let parentli = icon.getParent('li');
                  if (parentli.hasClass('unread')) {
                     toggleRead(parentli, /* read */ true);
                  }
               }
            });
         }
      }.bind(this),
   }).send();
}

function markAllAsRead() {
   new Request.API_2_0('notifications/all', {
      method: 'DELETE',
      onSuccess: function (response) {
         removeReadNotifications();
      }.bind(this),
   }).send();
}

function shouldDestroyNotificationElement(notification) {
   // Don't visually destroy notification elements in the dropdown if a new
   // page is loading. It looks weird to click on a link in a notification
   // and have it disappear.
   return notification && notification.get('data-leaving-page') === null;
}

// Takes an array of ids and removes the notifications from the dropdown if
// they exist. If no array is given then it removes ALL of the
// notifications.
function removeReadNotifications(notificationSigs) {
   if (notificationSigs === undefined) {
      Header.toggleHeaderDropdown(rtNotificationsContainer, false);
      updateCount(0);

      // Delete all children that have `data-notificationid` attributes.
      when(rtNotificationsDropdown, el => {
         el.getElements('li[data-notificationid]')
            .filter(shouldDestroyNotificationElement)
            .each(child => {
               child.destroy();
            });
      });

      // And if the history page is showing, mark them all as read.
      when(rtNotificationHistory, el => {
         let icons = el.getChildren('ul > li[data-notificationid] > a > i[.notification-icon]');
         icons.each(icon => {
            toggleRead(icon, /* read */ true);

            // The parent element needs this class for sorting reasons,
            // so let's keep that updated as well.
            let li = icon.getParent('li');
            if (li.hasClass('unread')) {
               toggleRead(li, /* read */ true);
            }
         });
      });
   } else {
      let count = getCount();
      notificationSigs.forEach(notificationSig => {
         let dropdownElement = rtNotificationsDropdown.getChildren(
            'li[data-notificationid="' + notificationSig + '"]'
         )[0];

         // Reset the page title
         document.title = originalTitle;

         if (shouldDestroyNotificationElement(dropdownElement)) {
            dropdownElement.destroy();
         }
         when(rtNotificationHistory, el => {
            let iconParent = el.querySelector('[data-notificationid="' + notificationSig + '"]');
            if (iconParent) {
               let icon = iconParent.getElement('.notification-icon');
               if (icon) {
                  icon.addClass('read');
                  icon.removeClass('unread');
               }
            }
         });
         count--;
      });
      if (count == 0) {
         Header.toggleHeaderDropdown(rtNotificationsContainer, false);
      }
      updateCount(count);
   }
}

/**
 * Mark all notifications as read, remove them from the drawer, and
 * close the drawer.
 */
when(markAllReadButton, el => {
   el.addEvent('click', markAllAsRead);
});

when(rtNotificationsToggle, el => {
   Header.setupHeaderDropdown(rtNotificationsToggle, rtNotificationsContainer, {
      unlock: function () {
         return getCount() > 0;
      },
   });

   // Add click listeners to children's silent-mark-read button to stop the
   // click from navigating to target.
   rtNotificationsDropdown.addEvent(
      'click:relay(li[data-notificationid] .notification-dismiss)',
      stopNavigation
   );
   window.addEvent('click:relay(li[data-notificationid])', markNotificationRead);

   // Subscribe to new notifications.
   // If io isn't defined, realtime is disabled or socket.io is broken.
   // If rtNotificationsToggle is unavailable, realtime is disabled.
   // Either way, we can continue without realtime support.
   if (App.realtimeEnabled && App.socketioRoom !== undefined) {
      let socket = io(App.realtimeHost, {
         // Try websocket first, since most users will be fine with it.
         transports: ['websocket', 'polling'],
      });

      // After connecting, subscribe to the room specific to the current user.
      socket.on('connect', () => {
         socket.emit('subscribe', { room: App.socketioRoom });
      });

      // When a new notification is received, add it to the dropdown,
      // increment the count and update the page title if we're on that page.
      socket.on('notification', notification => {
         switch (notification.event) {
            case 'notification': {
               // Update the count on the dropdown.
               updateCount(getCount() + 1);
               // Update the title count if the notification was pointing to
               // the current page.
               if (
                  notification.contextid === App.contextid &&
                  notification.context === App.context
               ) {
                  document.title = '(' + ++receivedCount + ') ' + originalTitle;
               }
               rtNotificationsDropdown.appendHTML(notification.html, 'top');
               break;
            }
            case 'markRead': {
               removeReadNotifications(notification.notificationSigs);
               break;
            }
            case 'markAllRead': {
               removeReadNotifications();
               break;
            }
         }
      });
   }
});
