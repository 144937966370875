import { Modal } from 'Shared/modal';

let widgets = {};

/**
 * Recaptcha is in an iframe, isn't responsive, and their 'compact' theme
 * looks bad. This leaves us with `transform: scale(...)` to make it fit
 * in the form.
 *
 * This calculation is fragile since it's dependent on the current styling
 * for recaptcha.
 */
function autoSize() {
   let captcha = container();

   if (!captcha) {
      return;
   }

   let scale = captcha.getParent().getSize().x / 304;
   scale = Math.min(1, Math.max(0.7, scale));

   captcha.setStyles({
      transform: 'scale(' + scale + ')',
   });
}

/**
 * Loads the recaptcha js API and renders it in the element .g-recaptcha.
 * Used for rendering the recaptcha after page load. This function can be
 * called multiple times and will only load the script once.
 *
 * If the API was added at page load, this function does nothing.
 */
let recaptchaLoaded = null;
function loadRecaptcha() {
   if (recaptchaLoaded) {
      return recaptchaLoaded;
   }

   return (recaptchaLoaded = new Promise(resolve => {
      window.recaptchaOnload = function () {
         resolve();
      };

      $E('head').append(
         new Element('script', {
            src: 'https://www.google.com/recaptcha/api.js?onload=recaptchaOnload&render=explicit',
         })
      );
   }));
}

let recaptchaRendered;
function renderRecaptcha() {
   if (recaptchaRendered) {
      return;
   }
   widgets.visible = grecaptcha.render(container(), {
      sitekey: container().get('data-sitekey'),
   });
   recaptchaRendered = true;
}

function container() {
   return $E('.g-recaptcha');
}

onDomReady(() => {
   window.addEvent('resize', autoSize);
   Modal.addEvent('onLoginUnload', () => {
      recaptchaRendered = false;
   });
});

let recaptchaPromiseResolver;
export const Recaptcha = {
   executeInvisible: function (element, sitekey, area) {
      if (!element) {
         return Promise.resolve();
      }
      if (!sitekey) {
         throw 'sitekey is ' + sitekey;
      }
      if (!area) {
         throw 'area is ' + area;
      }
      return loadRecaptcha().then(
         () =>
            new Promise(resolve => {
               recaptchaPromiseResolver = resolve;
               if (widgets[area] === undefined) {
                  const renderOptions = {
                     sitekey: sitekey,
                     size: 'invisible',
                     // We need to make this dynamic so we don't have a stale
                     // reference to the `resolve` function on the initially
                     // created Promise.
                     callback: token => recaptchaPromiseResolver(token),
                  };
                  widgets[area] = grecaptcha.render(element, renderOptions, false);
               } else {
                  grecaptcha.reset(widgets[area]);
               }
               grecaptcha.execute(widgets[area]);
            })
      );
   },

   container: container,
};
