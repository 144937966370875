import { store } from 'Shared/react-initialize';
import * as HeaderActions from 'Shared/Header/actions';

function onCartAddComplete(response) {
   if (shouldRedirectAfterAdd()) {
      window.location = window.shared_constants.BaseURI('TAG_CHECKOUT_CART');
      return;
   }
   if (response) {
      const miniCart = response.miniCart;
      store.dispatch(HeaderActions.updateMiniCart(miniCart));
   }

   window.scrollTo({ top: 0, behavior: 'smooth' });
   setTimeout(() => {
      const click = new Event('click', { bubbles: true });
      document.getElementById('miniCartDropdownToggle').dispatchEvent(click);
   }, 750);
}

// We should redirect if the mini cart is "simple" (e.g user has ton of products
// or is on a mobile device)
function shouldRedirectAfterAdd() {
   return document.getElementById('hydra-mini-cart-simple');
}

// We need to listen for this event whether or not we have a mini cart
// because we may need to redirect to the cart page.
listenGlobal('cart.addcomplete', onCartAddComplete);
