const supportsCustomElements = 'customElements' in window;
const supportsShadowDom = 'attachShadow' in document.documentElement;

const useShadowWrapper = supportsCustomElements && supportsShadowDom;

class ShadowWrapper extends HTMLElement {
   theShadowRoot;

   constructor() {
      super();

      this.querySelectorAll('[data-no-shadow]').forEach(node => {
         // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
         this.parentNode.append(node);
      });

      this.theShadowRoot = this.attachShadow({ mode: 'open' });
      Array.from(this.children).forEach(node => {
         this.theShadowRoot.append(node);
      });
   }

   queryAll($selector: any) {
      return this.theShadowRoot.querySelectorAll($selector);
   }
}

const defineShadowWrapper = () => {
   if (!useShadowWrapper) {
      return;
   }

   if (customElements.get('shadow-wrapper')) {
      return;
   }

   customElements.define('shadow-wrapper', ShadowWrapper);
};

defineShadowWrapper();

const querySelectorAllWithShadowed = (selector: any) => {
   const nodes = Array.from(document.querySelectorAll(selector));

   if (!useShadowWrapper) {
      return nodes;
   }

   const shadowWrappers = Array.from(
      document.querySelectorAll('shadow-wrapper')
   ) as ShadowWrapper[];

   const shadowedNodes = shadowWrappers.reduce((nodes, wrapper) => {
      const wrappedNodes = wrapper.queryAll(selector);
      // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      nodes.push(...Array.from(wrappedNodes));
      return nodes;
   }, []);
   return nodes.concat(shadowedNodes);
};

export { querySelectorAllWithShadowed };
