import { createRoot } from 'react-dom/client';
import { KeyboardNavigation } from 'Shared/keyboard_navigation';
import { DevicePickerModal } from '@ifixit/device-picker';
import { Initializer } from 'Shared/chakra-initialize';
import { initializeReactQuery } from 'Shared/react-query-initialize';
import { getSearchClient } from 'Guide/searchClient';

new KeyboardNavigation({
   keys: {
      'C-k': deviceNavigator,
      'M-k': deviceNavigator,
   },
});

export default function deviceNavigator() {
   if (document.querySelector('[data-id="devicePickerComponent"]')) {
      return;
   }
   const node = document.createElement('div');
   document.body.append(node);
   const root = createRoot(node);
   const DevicePickerWithChakra = new Initializer(DevicePickerModal);
   root.render(
      initializeReactQuery(
         <DevicePickerWithChakra
            defaultIsOpen={true}
            onSelect={device => (window.location.href = device.url)}
            algoliaConfig={{
               searchClient: getSearchClient(),
               indexPrefix: App.algoliaConfig['indexPrefix'],
            }}
            objectName={App.objectNameSingular}
            objectNamePlural={App.objectNamePlural}
            langid={App.lang}
            preferredStore={App.userPreferredStoreCode}
            treeNavigationProps={'showAreaButtons'}
         />
      )
   );
}
